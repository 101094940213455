<script lang="ts">

export default {
    name: "Header"
}

</script>

<template>
    <header class="fixed top-0 w-full left-0 z-10 bg-white customShadow py-5 px-3">
        <nav class="flex items-center justify-center">
            <img src="@/assets/logo.png" alt="logo" class="w-52">
        </nav>
    </header>
</template>

<style lang="scss" scoped>

.customShadow {
    box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);
    -webkit-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);
    -moz-box-shadow: 0px 3px 5px 0px rgba(0, 0, 0, 0.75);
}
</style>
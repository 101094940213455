import axios from "axios";
import ParamsModel from "./paramsModel";

class UserDto extends ParamsModel {
    uuid_user: string;
    note: string;
    static code_state: number = 200;

    static async updateLead(user: UserDto): Promise<number> {
        try {
            const response = await axios.post(
                "https://trk.everset.dev/ic/rescheduleAppointment",
                user,
                {
                    headers: {
                        token: "4D-&C+}n<1sBMI-Gr}OD5V_DCGp[5ZB",
                        "Access-Control-Allow-Origin": "*",
                    },
                }
            );
            console.log(
                "API Search lead call successful, response: ",
                response.data
            );
        } catch (error) {
            console.log("API Search lead call, error: ", error);
        }

        return this.code_state;
    }
}

export default UserDto;

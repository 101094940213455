<script lang="ts">

export default {
    name: "BotAvatar",
    props: {
        timestamp: String
    }
}
</script>

<template>
    <div class="flex flex-col items-center min-w-8 md:min-w-[45px]">
        <img src="@/assets/chat-avatar.png" alt="bot"
            class="h-8 w-8 md:h-[45px] md:w-[45px] object-contain block object-bottom">
        <span class="text-[12px] text-white"> {{ timestamp }}</span>
    </div>
</template>
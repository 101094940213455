<script lang="ts">
import { RouterLink, RouterView } from 'vue-router'
import Header from "./components/Header.vue";
import Footer from "./components/Footer.vue";
import Utils from "./shared/utils";

export default {
  name: "App",
  components: {
    Header,
    Footer,
  },
  created() {
    const utils = new Utils();
    utils.setUUIDfromURL();
  }
};

</script>

<template>
  <Header />

  <RouterView />

  <Footer />
</template>

<style scoped></style>
